import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { GetProformasEvent } from '../events/get_proformas_event';
import { Container, Typography } from '@mui/material';
import ImportCard from '../components/import_card';

const HistorialCompras: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector(state => state.proformas);

  useEffect(() => {
    dispatch(GetProformasEvent());
  }, [dispatch]);  // Incluimos 'dispatch' como dependencia

  return (
    <Container sx={{ padding: '20px' }}>
      <Typography
        variant='h4'
        sx={{
          fontWeight: '600',
          marginBottom: '30px',
        }}
      >
        Historial de compras
      </Typography>
      {proformas.map((proforma, index) => (
        <ImportCard
          key={index}
          id={proforma.id}
          title={proforma.productName}
          image={proforma.productImageUrl}
          status={proforma.currentStatus || 'Desconocido'}
          nextStep={`Próximo paso: ${proforma.nextStatus || 'Ninguno'}`}
          progressSteps={proforma.progressSteps || []}
          paymentProgress={proforma.paymentProgress || 0}
          amountPaid={proforma.amountPaid || 0}
          totalAmount={proforma.totalAmount || 0}
          debt={proforma.debt || 0}  // Pasamos debt como número
          dueDate="27 de Julio de 2024"
        />
      ))}
    </Container>
  );
};

export default HistorialCompras;
