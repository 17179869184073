import React from "react";
import { Box, Typography, Button } from "@mui/material";

const JoinSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f4f7fd",
        padding: "20px",
        borderRadius: "12px",
        maxWidth: "900px",
        margin: "40px auto",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="body1" sx={{ fontSize: "18px", color: "#6f6f6f", flex: 1 }}>
        Únete a las empresas que están automatizando su importación y sus operaciones con Contandem. Agendemos una reunión para conocernos!
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        href="https://calendly.com/contandem" // Link al Calendly
        target="_blank" // Abre en una nueva pestaña
        sx={{
          backgroundColor: "#0A66A5",
          color: "#fff",
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: "bold",
        }}
      >
        Agéndanos
      </Button>
    </Box>
  );
};

export default JoinSection;
