// src/components/ProductFilter.tsx
import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { Card, Checkbox, Divider, Grid, Slider, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SetProductFilterEvent } from '../events/set_product_filter_event';
import { CategoryProductEnum } from '../../domain/entities';

const mapCategory = (category: CategoryProductEnum) => {
    switch (category) {
        case CategoryProductEnum.BATHROOM:
            return 'Baño';
        case CategoryProductEnum.CLOTHING:
            return 'Vestuario';
        case CategoryProductEnum.CONTRUCTION:
            return 'Construcción';
        case CategoryProductEnum.ELECTRONIC:
            return 'Electrónica';
        case CategoryProductEnum.FURNITURE:
            return 'Muebles';
        case CategoryProductEnum.KITCHEN:
            return 'Cocina';
        case CategoryProductEnum.OTHER:
            return 'Otros';
        default:
            return '';
    }
}

const ProductFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.product);

    return (
        <Card elevation={5} sx={{
            margin: '10px',
            padding: '20px',
            borderRadius: '16px',
            backgroundColor: '#f9f9f9'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <FilterAltIcon sx={{ marginRight: '8px' }} /> Filtros
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        Categorías
                    </Typography>
                    <Grid container direction='column'>
                        {Object.values(CategoryProductEnum).map((category) => (
                            <Grid item key={category}>
                                <Checkbox
                                    checked={filter.categories.includes(category)}
                                    onChange={(_, checked) => {
                                        const updatedCategories = checked 
                                            ? [...filter.categories, category] 
                                            : filter.categories.filter((c) => c !== category);
                                        dispatch(SetProductFilterEvent({ ...filter, categories: updatedCategories }));
                                    }}
                                />
                                {mapCategory(category)}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        Precio
                    </Typography>
                    <Slider
                        value={filter.range}
                        onChange={(_, newValue) => dispatch(SetProductFilterEvent({ ...filter, range: newValue as number[] }))}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `$${value}`}
                        min={10}
                        max={500}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProductFilter;
