import apiRepository from "../../data/repository";
import LoginUserUsecase, { LoginParams } from "../../domain/usecases/login_user_usecase";
import { setStatusUser, StatusUserEnum } from "../redux/slices/user_slice";
import { GetProfileEvent } from './get_profile_event';

export const LoginEvent = (params: LoginParams) => async (dispatch) => {
    const usecase = new LoginUserUsecase(apiRepository);
    dispatch(setStatusUser(StatusUserEnum.Loading));

    const result = await usecase.execute(params);

    result.fold(
        () => {
            dispatch(setStatusUser(StatusUserEnum.Error));
        },
        (token: string) => {
            // Almacena el token
            localStorage.setItem('token', token);

            // Actualiza el estado de usuario obteniendo el perfil
            dispatch(GetProfileEvent());

            // Actualiza el status a Success
            dispatch(setStatusUser(StatusUserEnum.Success));
        }
    );
}
