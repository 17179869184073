// src/components/ProductCard.tsx
import React from 'react';
import { ProductEntity } from '../../domain/entities';
import { Card, Typography, CardContent } from '@mui/material';

interface ProductCardProps {
    product: ProductEntity;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    return (
        <Card elevation={3} sx={{
            maxWidth: 300,
            margin: '10px',
            borderRadius: '8px',
            overflow: 'hidden'
        }}>
            <div style={{
                height: 200,
                backgroundImage: `url(${product.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }} />
            <CardContent>
                <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold', minHeight: '50px', overflow: 'hidden' }}>
                    {product.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', minHeight: '40px', overflow: 'hidden' }}>
                    {product.description}
                </Typography>
                <Typography variant="body2" sx={{ color: 'GrayText', fontWeight: 'bold', mt: 1 }}>
                    $ {product.price} USD
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProductCard;
