import React, { useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { enqueueSnackbar } from 'notistack';
import { GetProfileEvent } from '../../events/get_profile_event';
import { StatusUserEnum } from '../../redux/slices/user_slice'; // Ajusta la ruta según sea necesario

const SessionBackdrop: React.FC = () => {
    const { user, status: userStatus } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const hasShownWelcome = useRef(false);
  
    useEffect(() => {
      dispatch(GetProfileEvent());
    }, [dispatch]);
  
    useEffect(() => {
      if (user !== null && userStatus === StatusUserEnum.Success && !hasShownWelcome.current) {
        enqueueSnackbar('Bienvenido', { variant: 'success', autoHideDuration: 4000 });
        hasShownWelcome.current = true;
      }
    }, [user, userStatus]);
  
    return null;
  };
  
  export default SessionBackdrop;