import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import './App.css';

import LoginPage from "./presentation/pages/login";
import ContainersPage from "./presentation/pages/containers";
import RegisterPage from "./presentation/pages/register";
import LoadingBackdrop from "./presentation/components/layout/loading_backdrop";
import SessionBackdrop from './presentation/components/layout/session_redirect';

import PrivateRoute from './presentation/components/private_route'; 

import Footer from './presentation/components/layout/footer';
import { Header } from './presentation/components/layout/header';
import HomePage from './presentation/pages/home';
import ProductsPage from './presentation/pages/products';
import HistorialComprasPage from "./presentation/pages/historialcompras";
import EstadoDeCuenta from "./presentation/pages/estadodecuenta";
import CartPage from "./presentation/pages/cart";
import AcceptUserPage from './presentation/pages/user_accept';
import PrivacyPolicy from './presentation/components/privacy_policy'; 
import SuccessRegisterPage from './presentation/pages/success_register';


const App = () => {

    return (
        <Fragment>
            <SnackbarProvider />
            <BrowserRouter>
                <LoadingBackdrop />
                <Header />
                <SessionBackdrop /> 
                <Routes>
                    <Route path="/" element={
                        <Fragment>
                            <HomePage />
                        </Fragment>
                    } />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/success-register" element={<SuccessRegisterPage />} />
                    <Route path="/accept-user/:user_id" element={<AcceptUserPage />} />
                    <Route path="/containers" element={
                        <PrivateRoute>
                            <Fragment>
                                <ContainersPage />
                            </Fragment>
                        </PrivateRoute>
                    } />
                    <Route path="/products" element={
                        <PrivateRoute>
                            <Fragment>
                                <ProductsPage />
                            </Fragment>
                        </PrivateRoute>
                    } />
                    <Route path="/historialcompras" element={
                        <PrivateRoute>
                            <Fragment>
                                <HistorialComprasPage />
                            </Fragment>
                        </PrivateRoute>
                    } />
                    <Route path="/cart" element={
                        <PrivateRoute>
                            <Fragment>
                                <CartPage />
                            </Fragment>
                        </PrivateRoute>
                    } />
                    <Route path="/estadodecuenta/:id" element={
                        <PrivateRoute>
                            <Fragment>
                                <EstadoDeCuenta />
                            </Fragment>
                        </PrivateRoute>
                    } />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} /> 
                </Routes>
                <Footer />
            </BrowserRouter>
        </Fragment>
    );
};

export default App;
