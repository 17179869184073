
import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import AppImages from "../../../theme/app_images";

export const Footer: FC = (): ReactElement => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "300px",
                backgroundColor: "#F0F0F0",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
        >
            <Container sx={{
                width: '100%',
                height: '100%',
                padding: '1rem',
            }}
            >
                <Grid container spacing={6} sx={{ height: '100%', }}>
                    <Grid item xs={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                            }}
                        >
                            <img src={AppImages.logo} alt='logo' />
                            <Typography color="textSecondary" variant="subtitle1">
                                RÍO LOCO 151 - SITIO 8A
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                            }}
                        >
                            <Typography color="textSecondary" variant="subtitle1" sx={{ width: "70%" }} textAlign="start">
                                C O N T A N D E M
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle1" textAlign="start" sx={{ width: "70%" }}>
                                Nosotros
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle1" textAlign="start" sx={{ width: "70%" }}>
                                Operación
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle1" textAlign="start" sx={{ width: "70%" }}>
                                Contacto
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                            }}
                        >
                            <Typography color="textSecondary" variant="subtitle1">
                                CONTACTO
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle1">
                                hola@contandem.cl | +56 9 9322 2874
                            </Typography>
                            <Grid container>
                                <Grid item>
                                </Grid>
                                <Grid item>
                                </Grid>
                                <Grid item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;