import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import AppImages from '../../theme/app_images';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { RegisterStatusEnum } from '../redux/slices/register_slice';
import { registerUserEvent } from '../events/get_register_event';
import { useNavigate } from 'react-router-dom';

const RegisterHome = () => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [business_rut, setBusinessRut] = useState('');
  const [business_name, setBusinessName] = useState('');
  const [business_category, setBusinessCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { status } = useAppSelector((state) => state.register);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validateChileanRUT = (rut: string): boolean => {
    const cleanRut: string = rut.replace(/[^0-9kK]/g, '').toUpperCase();
    const rutBody: string = cleanRut.slice(0, -1);
    const dv: string = cleanRut.slice(-1);

    if (rutBody.length < 7) return false;

    let total: number = 0;
    let factor: number = 2;

    for (let i = rutBody.length - 1; i >= 0; i--) {
      total += parseInt(rutBody[i], 10) * factor;
      factor = factor === 7 ? 2 : factor + 1;
    }

    let expectedDV: string | number = 11 - (total % 11);
    if (expectedDV === 11) expectedDV = '0';
    else if (expectedDV === 10) expectedDV = 'K';
    else expectedDV = expectedDV.toString();

    return expectedDV === dv;
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone: string) => {
    const re = /^[0-9]{8,15}$/;
    return re.test(phone);
  };

  const validateRUT = (rut: string) => {
    return rut.length > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!first_name || !last_name) {
      setError('Nombre y Apellido son requeridos.');
      setLoading(false);
      return;
    }

    if (!validateChileanRUT(business_rut)) {
      setError('Por favor, ingresa un RUT válido.');
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError('Por favor, ingresa un correo electrónico válido.');
      setLoading(false);
      return;
    }

    if (!validatePhone(phone)) {
      setError('Por favor, ingresa un número de teléfono válido.');
      setLoading(false);
      return;
    }

    if (!validateRUT(business_rut)) {
      setError('Por favor, ingresa un RUT válido.');
      setLoading(false);
      return;
    }

    if (!business_name || !business_category) {
      setError('Nombre de la Empresa y Categoría son requeridos.');
      setLoading(false);
      return;
    }


    // Aquí mandamos a back!

    setLoading(false);

    dispatch(registerUserEvent(
      first_name,
      last_name,
      email,
      phone,
      business_rut,
      business_name,
      business_category,
    ));


  };

  useEffect(() => {
    if (status === RegisterStatusEnum.Success) {
      navigate('/success-register');
    }
  }, [status]);


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${AppImages.backhomeregister})`,
        backgroundSize: 'cover',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          width: '100%',
          backgroundColor: '#f4f7fd', 

          padding: '40px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginLeft: '10%',

          color: '#0A66A5', 
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom textAlign="center">
          ¡Regístrate en el formulario!
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {[{
            label: 'Nombre', id: 'firstName', value: first_name, onChange: (e) => setFirstName(e.target.value)
          }, {
            label: 'Apellido', id: 'lastName', value: last_name, onChange: (e) => setLastName(e.target.value)
          }, {
            label: 'Email', id: 'email', value: email, onChange: (e) => setEmail(e.target.value)
          }, {
            label: 'Teléfono', id: 'phone', value: phone, onChange: (e) => setPhone(e.target.value)
          }, {
            label: 'RUT Empresa', id: 'business_rut', value: business_rut, onChange: (e) => setBusinessRut(e.target.value)
          }, {
            label: 'Nombre Empresa', id: 'business_name', value: business_name, onChange: (e) => setBusinessName(e.target.value)
          }, {
            label: 'Categoría Empresa', id: 'business_category', value: business_category, onChange: (e) => setBusinessCategory(e.target.value)
          }].map((field) => (
            <TextField
              key={field.id}
              margin="normal"
              required
              fullWidth
              label={field.label}
              value={field.value}
              onChange={field.onChange}
              InputLabelProps={{ style: { color: '#0A66A5' } }}
              sx={{
                '& .MuiInputBase-input': { color: '#0A66A5' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#0A66A5' },
                  '&:hover fieldset': { borderColor: 'lightgray' },
                  '&.Mui-focused fieldset': { borderColor: '#0A66A5' },
                },
              }}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              style={{
                width: '50%',
                borderRadius: '10px',
                height: '40px',
                backgroundColor: '#0A66A5',
                color: '#fff',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={16} /> : 'Enviar'}
            </Button>
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterHome;
