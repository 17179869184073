// /frontend_contandem/src/presentation/components/operation_section.tsx
import React from 'react';
import { Box, Container, Typography, Grid, Card} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AppImages from '../../theme/app_images';

const OperationSection: React.FC = () => {
    const operations = [
        { icon: <ShoppingCartIcon sx={{ fontSize: 40, color: '#0A66A5' }} />, label: 'Compramos en conjunto' },
        { icon: <PrecisionManufacturingIcon sx={{ fontSize: 40, color: '#0A66A5' }} />, label: 'Producimos a medida' },
        { icon: <LocalShippingIcon sx={{ fontSize: 40, color: '#0A66A5' }} />, label: 'Despachamos seguro' },
        { icon: <DirectionsBoatIcon sx={{ fontSize: 40, color: '#0A66A5' }} />, label: 'Internamos tus productos' },
      ];

  return (
    <Box sx={{ paddingY: 8, backgroundColor: '#FFFFFF' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontWeight: 700,
            fontSize: 40,
            textAlign: 'center',
            color: '#FFFFFF',
            backgroundColor: '#0A66A5',
            padding: 1,
            borderRadius: 1,
            marginBottom: 2,
          }}
        >
          Nuestra operación
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{
            fontWeight: 400,
            fontSize: 30,
            textAlign: 'center',
            color: '#0A66A5',
            marginBottom: 4,
          }}
        >
          Nos ocupamos de todo el proceso,{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            desde agrupar demanda hasta la internación de los productos
          </Box>{' '}
          en nuestro país.
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            {operations.map((operation, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                  <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, backgroundColor: '#F4F7FA', borderRadius: 2, boxShadow: 'none' }}>
                    {operation.icon}
                  </Card>
                  
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body1" sx={{ fontSize: 30, fontWeight: 'bold', color: '#0A66A5' }}>
                    {operation.label}
                    </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              component="img"
              src={AppImages.operacion_new}
              alt="Operación de contenedores"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};


export default OperationSection;
