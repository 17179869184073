import React from "react";
import Slider from "react-slick";
import { Box, Typography, Card, Avatar, Stack } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppImages from '../../theme/app_images';

const testimonials = [
  {
    name: "Miguel Cumsille",
    title: "KEY",
    text: "Contandem nos permitió la posibilidad de importar productos sin la necesidad de comprar un contenedor completo. Pudimos comprar a menor precio con nuestra marca. Seguiremos confiando en Contandem! ",
    image: AppImages.cum,
  },
  {
    name: "Elías Guerra",
    title: "Max Todo",
    text: "Estamos muy agradecidos de que exista y se haya creado la posibilidad de que una pequeña empresa como la nuestra en Rancagua, tenga la posibilidad de importar mercadería.",
    image: AppImages.elias,
  },
  {
    name: "Juan Abarca",
    title: "Selpak",
    text: "Como Selpak estamos muy agradecidos de la oportunidad de importar que ofrece Contandem, ya que a las pequeñas y medianas empresas se nos hace muy dificil importar, ya sea por los volúmenes que se solicitan y por la expertiz que debemos tener a la hora de realizar esta operación.",
    image: AppImages.juan,
  },
];

const TestimonialsCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2, 
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,         
        autoplaySpeed: 3000, 
        cssEase: "ease",        // Tipo de transición para deslizamiento suave
        fade: false,  
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1, 
              slidesToScroll: 1,
            },
          },
        ],
      };
    

      return (
        <Box sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto", padding: "20px 0" , marginTop: 3, marginBottom: 4, }}>
          <Typography variant="h4" component="h2" align="center" gutterBottom sx={{ fontWeight: "bold", marginBottom: 4, color: "#003366" }}>
            ¿Qué opinan nuestros clientes?
          </Typography>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <Card
                key={index}
                sx={{
                  padding: 4,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  maxWidth: "500px",
                  minHeight: "200px",  // Altura mínima para todas las tarjetas
                  maxHeight: "200px",
                  margin: "0 auto",
                  backgroundColor: "#f4f7fd"
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
                  <Avatar
                    src={testimonial.image}
                    alt={testimonial.name}
                    sx={{ width: 60, height: 60 }}
                  />
                  <Box>
                    <Typography variant="h6" component="h3" fontWeight="bold" sx={{ color: "#003366" }}>
                      {testimonial.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: "300", color: "#0077cc" }}>
                      {testimonial.title}
                    </Typography>
                  </Box>
                </Stack>
                <Typography variant="body1" sx={{ fontSize: "16px", color: "#333", textAlign: "left", lineHeight: "1.6" }}>
                  “{testimonial.text}”
                </Typography>
              </Card>
            ))}
          </Slider>
        </Box>
      );
    };
    

export default TestimonialsCarousel;

