// src/presentation/components/PrivateRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/hooks';
import { StatusUserEnum } from '../redux/slices/user_slice';

interface PrivateRouteProps {
  children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { status: userStatus } = useAppSelector(state => state.user);

  if (userStatus === StatusUserEnum.Success) {
    // El usuario está autenticado, renderiza el componente hijo
    return children;
  } else if (userStatus === StatusUserEnum.Loading) {
    // Puedes mostrar un indicador de carga si el estado es 'Loading'
    return <div>Cargando...</div>;
  } else {
    // El usuario no está autenticado, redirige al inicio de sesión
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
