import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserEntity } from '../../../domain/entities'

export enum StatusUserEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}
export interface UserState {
    user: UserEntity | null;
    status: StatusUserEnum;
}

const initialState = {
    user: null,
    status: StatusUserEnum.Initial
} as UserState;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserEntity | null>) {
            if (action.payload === null) {
              state.user = null;
              state.status = StatusUserEnum.Initial;
            } else {
              state.user = action.payload;
              state.status = StatusUserEnum.Success; 
            }
          },
        setStatusUser(state, action: PayloadAction<StatusUserEnum>) {
            state.status = action.payload
        }
    },
})

export const { setUser, setStatusUser } = userSlice.actions
export default userSlice.reducer
