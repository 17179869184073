import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Link } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  return (
    <Box sx={{ padding: 4, maxWidth: "800px", margin: "0 auto", color: "#333" }}>
      <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ fontWeight: "bold", marginBottom: 2 }}>
        Política de Privacidad
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary" paragraph>
        Fecha de vigencia: 1 noviembre 2024
      </Typography>
      <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
        Bienvenido(a) a app.contandem.cl ("nosotros", "nuestro" o "la Aplicación"). Nos comprometemos a proteger su
        privacidad y a cumplir con las leyes chilenas aplicables y las políticas de Google Play en materia de protección
        de datos.
      </Typography>

      <List>
        <ListItem disableGutters>
          <ListItemText
            primary="1. Información que Recopilamos"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary={
              <>
                <Typography variant="body1" sx={{ lineHeight: 1.6, marginTop: 1 }}>
                  Recopilamos los siguientes tipos de información:
                </Typography>
                <Typography variant="body2" component="ul" sx={{ paddingLeft: 3, marginTop: 1, lineHeight: 1.6 }}>
                  <li>Información Personal: Nombre, dirección de correo electrónico, número de teléfono u otra información de contacto que usted nos proporcione voluntariamente.</li>
                  <li>Información de Uso: Datos sobre cómo interactúa con la Aplicación, como las funciones que utiliza y las páginas que visita.</li>
                  <li>Información del Dispositivo: Modelo del dispositivo, sistema operativo, identificadores únicos y datos de conexión.</li>
                </Typography>
              </>
            }
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="2. Cómo Usamos su Información"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary={
              <Typography variant="body2" component="div" sx={{ lineHeight: 1.6, marginTop: 1 }}>
                Utilizamos su información para:
                <ul style={{ paddingLeft: 20, marginTop: 1 }}>
                  <li>Proveer y mejorar nuestros servicios.</li>
                  <li>Personalizar su experiencia en la Aplicación.</li>
                  <li>Comunicarnos con usted acerca de actualizaciones, promociones u otra información relevante.</li>
                  <li>Analizar el uso de la Aplicación para mejorar su funcionalidad y rendimiento.</li>
                </ul>
              </Typography>
            }
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="3. Compartir su Información"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary={
              <Typography variant="body2" component="div" sx={{ lineHeight: 1.6, marginTop: 1 }}>
                No compartimos su información personal con terceros, excepto en las siguientes circunstancias:
                <ul style={{ paddingLeft: 20, marginTop: 1 }}>
                  <li><strong>Con su Consentimiento:</strong> Cuando usted nos autoriza explícitamente.</li>
                  <li><strong>Proveedores de Servicios:</strong> Con terceros que nos ayudan a operar la Aplicación, siempre bajo acuerdos de confidencialidad.</li>
                  <li><strong>Obligaciones Legales:</strong> Si es requerido por ley o en respuesta a procesos legales.</li>
                </ul>
              </Typography>
            }
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="4. Seguridad de los Datos"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary="Implementamos medidas de seguridad razonables para proteger su información personal contra accesos no autorizados, pérdida o destrucción."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="5. Sus Derechos"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary={
              <Typography variant="body2" component="div" sx={{ lineHeight: 1.6, marginTop: 1 }}>
                De acuerdo con la Ley N° 19.628 sobre Protección de la Vida Privada, usted tiene derecho a:
                <ul style={{ paddingLeft: 20, marginTop: 1 }}>
                  <li>Acceder a su información personal que mantenemos.</li>
                  <li>Solicitar la rectificación, cancelación o bloqueo de sus datos cuando estén incorrectos o desactualizados.</li>
                  <li>Oponerse al procesamiento de sus datos para fines específicos.</li>
                </ul>
                Para ejercer estos derechos, por favor contáctenos en <Link href="mailto:hola@contandem.cl">hola@contandem.cl</Link>.
              </Typography>
            }
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="6. Cookies y Tecnologías Similares"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary="La Aplicación puede utilizar cookies u otras tecnologías similares para mejorar su experiencia. Usted puede configurar su dispositivo para rechazar cookies, pero esto puede afectar la funcionalidad de la Aplicación."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="7. Enlaces a Sitios de Terceros"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary="Nuestra Aplicación puede contener enlaces a sitios web o servicios de terceros que no están bajo nuestro control. No somos responsables por las prácticas de privacidad de dichos sitios."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="8. Cambios a esta Política de Privacidad"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary="Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Notificaremos cualquier cambio publicando la nueva política en esta página y actualizando la fecha de vigencia."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary="9. Contacto"
            primaryTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            secondary="Si tiene preguntas o inquietudes sobre esta Política de Privacidad, por favor contáctenos en hola@contandem.cl."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default PrivacyPolicy;

